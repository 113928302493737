.support__form {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.support__textarea {
  margin-bottom: 1rem;
  resize: none;
  padding: 0.5rem;
  height: 4rem;
  font-size: 1rem;
}
.support__button {
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}
