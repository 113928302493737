* {
  margin: 0;
  padding: 0;
  user-select: none;
 font-family: Arial, Helvetica, sans-serif;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #192032;
  background-color: white;
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: white;
}
.card-container {
  /* background-color: #2a3542; */
  background: #fafafa;
  border-radius: 3px;
  padding: 1.5rem 0;
  /* color: #b9caed; */
  color: #000;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.page-title {
  padding: 1.5rem;
  font-size: 24px;
}
.notes {
  color: black;
}
@media (max-width: 450px) {
  .page-title {
    font-size: 14px;
  }
}
table {
  margin-top: 30px;
  width: 100%;
  text-align: center;
  padding: 1rem;
}
th {
  /* background-color: #013da8; */
  background: #4530B2;
}
th,
td {
  padding: 1rem;
}
tr {
  /* background-color: rgb(31, 30, 30); */
  background: #fafafa;
  /* color: #b9caed; */

  font-weight: 500;
}

tbody tr {
  color: #000;
}

@media (max-width: 998px) {
  table {
    font-size: 9px;
    width: 100%;
  }
  th,
  td {
    padding: 1rem 2px;
  }
  table td {
    border: 1px solid #ddd;
  }
}
@media (max-width: 500px) {
  table {
    padding: 2px;
  }
  .hidden {
    display: none;
  }
}

@media (max-width: 380px) {
  table {
    overflow: auto;
  }
}

.light__theme {
  background: white;
}
.light__themeText {
  color: black;
}
.dark__pink {
  /* background: #1CBEF5; */
    background-color: #4530B2;
  background: linear-gradient(to right, #4530B2, #1CBEF5);
  /* background-image: linear-gradient(to bottom right, #1CBEF5, #4530B2); */
  color: white;
}
.dark__bgPink {
  background: #859100;
  width: 100%;
  height: 100vh;
}
.dark__bgBlue {
  /* background: #4530B2; */
  background: linear-gradient(to right, #1CBEF5, #4530B2 );
  width: 100%;
  height: 100vh;
}
.dark__blue {
  /* background: #1CBEF5; */
  background-image: linear-gradient(to right, #4530b2, #1CBEF5);
  color: white;
}

.dark__pinkText {
  color: #859100;
}
.dark__blueText {
  color: #5E4F52;
  /* color:#746CE9 */
  /* color: #38338A  */
}

.dark__violet {
  background-color:#4530B2 ;
}

.cancel__btn{
      color: white;
      padding: 0.5rem 1rem;
      border: none;
      outline: none;
      margin-right: 1.5rem;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
      background-color: #4530B2;
}