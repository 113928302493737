.up-nav {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.pointer {
  cursor: pointer;
}
.right-main {
  display: flex;
  align-items: center;
  justify-content: center;
}
.center-main {
  flex: 1;
  display: flex;
  justify-content: center;
  padding-left: 2rem;
  color: black;
}
.avatar-container {
  position: relative;
  overflow: hidden;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}
.avatar-container:hover .edit_btn {
  display: block;
}
.edit_btn {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  background: rgba(0, 0, 0, 0.151);
  z-index: 10;
  cursor: pointer;
  padding: 1rem;
  width: 100%;
}
.pic {
  width: 100%;
  object-fit: contain;
  background: orange;
}
.spacing-left {
  margin-left: 0.7rem;
}

.btn-shrink {
  display: none;
}
.btn-menu {
  display: block;
}
.back_btn {
  padding: 0.5rem 2rem;
  color: white;
  background: #013da8;
  font-size: 1rem;
  border: none;
  margin-bottom: 1rem;
  border-radius: 5px;
  display: none;
}
@media (min-width: 1180px) {
  .btn-shrink {
    display: block;
  }
  .btn-menu {
    display: none;
  }
}

@media (max-width: 500px) {
  .center-main {
    font-size: 14px;
  }
  .avatar-container {
    width: 2rem;
    height: 2rem;
  }
  .edit_btn {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    background: rgba(0, 0, 0, 0.151);
    z-index: 10;
    cursor: pointer;
    padding: 0.5rem;
    width: 100%;
  }
  .back_btn {
    display: block;
  }
}
