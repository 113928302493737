.overlay {
  width: 100vw;
  height: 100vh;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
}
.popup-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.popup-container {
  width: 400px;
  padding: 1rem;
  background: white;
}
.popup-title {
  color: #191e31;
}
.popup-form {
  margin-top: 1rem;
}
.popup-form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}
.popup-form-label {
  margin-bottom: 0.5rem;
  color: black;
  font-size: 0.8rem;
}
.popup-form-input {
  padding: 0.5rem 1rem;
}
.popup-form-button {
  color: white;
  background: #013da8;
  border: none;
  padding: 0.75rem 1.5rem;
  margin-top: 0.5rem;
  cursor: pointer;
}
.btn-clear {
  background: #191e31;
  margin-right: 0.5rem;
}
.noticeBoard-items {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1.5rem;
}
.noticeBoard-item {
  width: 250px;
  height: 250px;
  background: #013da8;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.noticeBoard-select {
  width: 100%;
  background: #191e31;
  color: white;
  padding: 0.8rem 1rem;
  font-size: 1rem;
}
.btn-big {
  height: 3rem;
  font-size: 1rem;
  border: 1px solid white;
}
.noticeBoard-details {
  margin: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.message-container {
  /* background: #191e31; */
  background: #dadada;
  color: black;
  padding: 1rem 1.5rem;
  position: relative;
}
.message {
  font-size: 1.3rem;
}
.time {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 0.8rem;
  margin: 0.2rem;
  color: gray;
}

@media (max-width: 500px) {
  .btn-big {
    font-size: 0.8rem;
    padding: 0.3rem;
  }
  .message-container {
    padding-bottom: 1.5rem;
  }
}
