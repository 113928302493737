.nameContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 0;
  color: black;
}
.nameContainer h3 {
  font-weight: 500;
  font-size: 1.5rem;
}
.nameContainer p {
  border: 1px solid black;
  font-size: 1.3rem;
  padding: 0.5rem 0.75rem;
  border-radius: 2px;
  font-weight: bold;
}

@media (max-width: 500px) {
  .nameContainer h3 {
    font-size: 1.1rem;
  }
  .nameContainer p {
    padding: 0.5rem 1rem;
  }
}

@media (max-width: 500px) {
  .nameContainer p {
    font-size: 0.9rem;
  }
}
