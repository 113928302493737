.form-list {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  margin-bottom: 1rem;
}
.label {
  width: 200px;
  /* color: #8698b1; */
  color: #000;
  font-weight: 600;
  font-size: 20px;
}
.stacking__select {
  /* background: #383d54; */
  /* background: #fff; */
  background: #dadada;
  color: black;
  padding: 0.5rem;
  border: none;
  font-size: 16px;
  width: 100%;
  outline: none;
}
.stacking__input {
  /* background: #383d54; */
  background: #dadada;
  /* color: white; */
  color: black;
  padding: 0.5rem;
  border: none;
  font-size: 16px;
  width: 100%;
}
.btn_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.addFunds__infoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.addFunds__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  font-size: 1.2rem;
  margin: 1rem 0;
}
.info__text {
  margin-bottom: 0.5rem;
  display: flex;
  gap: 0.2rem;
  cursor: pointer;
}
.alert__copied {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%);
  background: white;
  color: green;
  font-size: 1.2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 0.5rem 1rem;
}

@media (max-width: 500px) {
  .form-list {
    flex-direction: column;
    align-items: flex-start;
  }
  .label {
    font-size: 1rem;
  }
  .input,
  .select {
    margin-top: 1rem;
    min-width: 150px;
  }
  .addFunds__info {
    font-size: 0.8rem;
  }
}
@media (max-width: 750px) {
  .addFunds__info {
    font-size: 0.8rem;
  }
}

@media (max-width: 400px) {
  .info__text--1 {
    font-size: 11px;
  }
}
@media (max-width: 350px) {
  .info__text--1 {
    font-size: 9px;
  }
}
