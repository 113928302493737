.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #192032; */
  background: #1CBEF5;
  flex-direction: column;
}

.login-container {
  background: #fafafa;
  border-radius: 4px;
  width: 400px;
  height: 450px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.login-title {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  color: black;
  margin-bottom: 1.5rem;
}
.login-logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.image-container {
  text-align: center;
}
.form-group-login {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}
.form-label-login {
  margin-bottom: 0.5rem;
  color: gray;
  font-size: 0.9rem;
}
.login__form {
  width: 100%;
}
.form-input-login {
  background-color: white;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: black;
  border: none;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.form-submitBtn-login {
  /* background: #192032; */
    background-image: linear-gradient(to right, #4530b2, #1CBEF5);
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 3.2rem;
  font-size: 1.2rem;
  margin-top: 1rem;
}
.password__container {
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.password__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  flex: 0.1;
}
.form-password-input-login {
  background-color: white;
  border-bottom: 1px solid gray;
  font-size: 1rem;
  color: black;
  flex: 1;
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
}

.password__container:focus-within,
.form-input-login:focus {
  /* border: 1px solid #9dc732; */
  border: 1px solid #5E4F52;
}

.support {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 1rem;
  background: white;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  gap: 0.3rem;
  padding: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.support__modal--overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
}
.support__modal {
  background: white;
  position: fixed;
  bottom: 10%;
  right: 5%;
  width: 300px;
  height: auto;
  padding: 0.25rem;
  z-index: 10;
  border-radius: 5px;
}
.support__modalHeader {
  padding: 0 1rem;
  padding-top: 0.3rem;
}
.support__modalInput {
  font-size: 0.8rem;
  padding: 0.3rem 0.5rem;
  margin-bottom: 0.5rem;
}
.support__modal--clear {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  cursor: pointer;
}
.support__button {
  background-image: linear-gradient(to right, #4530b2, #1CBEF5);
}
.maintenance {
  margin-top: 2rem;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.forgot__password {
  margin-top: 1.2rem;
  text-decoration: underline;
  width: 125px;
}


.modal___overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
}

.modal___outer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal___container {
  background: #fcfcfc;
  color: black;
  width: 400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 1rem;
  z-index:10;
}

.form___id {
  background-color: white;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: black;
  border: none;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.modal___input {
  display: flex;
  flex-direction: column;
  padding: 3rem 1rem;
}

.Modal___submit {
  background-image: linear-gradient(to right, #4530b2, #1CBEF5);
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  width: 90%;
  height: 3.2rem;
  font-size: 1.2rem;
  margin-top: -0.5rem;
  margin-left: 1.2rem;
  margin-bottom: 2rem;
}