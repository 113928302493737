.bank_list {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
}
.update-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5rem;
}
.update-bank-title {
  font-size: 1.5rem;
}
.update-bank-btn {
  padding: 0.5rem 1rem;
  /* background: #191e31; */
  color: white;
  border: none;
  font-size: 1rem;
  border-radius: 2px;
  cursor: pointer;
}
.update-bank-btn:disabled {
  background-color: gray;
  cursor: not-allowed;
}
.title {
  width: 200px;
  /* color: #8698b1; */
  color: #000;
  font-weight: 600;
  font-size: 20px;
}
.title1 {
  width: 160px;
  /* color: #8698b1; */
  color: #000;
  font-weight: 600;
  font-size: 20px;
}

.bank_ipbox {
  /* background-color: #191e31; */
  background: #dadada;
  /* border: 1px solid #383d54; */
  border: none;
  padding: 0.5rem;
  /* color: #8698b1; */
  color: #000;
  font-size: 16px;
  width: 100%;
}

.submit__btn {
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  font-size: 16px;
}

.submit__btn {
  /* background-color: #023ea8; */
  cursor: pointer;
}
.notes {
  margin: 20px;
  font-size: 14px;
  color: #8698b1;
  font-weight: 400;
}

@media (max-width: 800px) {
  .update-bank-title {
    font-size: 1rem;
  }
}
@media (max-width: 500px) {
  .update-btn-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .update-bank-title {
    margin-bottom: 1rem;
  }
  .update-bank-btn {
    align-self: flex-end;
  }
  .bank_list {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem;
    width: 100%;
  }
  .bank_ipbox {
    width: 85%;
  }
  .title {
    font-size: 1rem;
  }
  .submit_btn {
    margin-top: 2rem;
  }
}
