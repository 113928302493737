.message__container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.message {
  padding: 0.5rem 1rem;
  width: 250px;
  font-size: 1rem;

  position: relative;
}
.message-success {
  color: green;
}
.message-error {
  color: red;
}
.wrapper {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 3px;
}
