.loader__center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.ldio-xhaaqbby8go div {
  box-sizing: border-box !important;
}
.ldio-xhaaqbby8go > div {
  position: absolute;
  width: 144px;
  height: 144px;
  top: 28px;
  left: 28px;
  border-radius: 50%;
  border: 16px solid #000;
  border-color: #4530B2 transparent #4530B2 transparent;
  border-color: #4530B2 transparent #4530B2 transparent;
  animation: ldio-xhaaqbby8go 1s linear infinite;
}

.ldio-xhaaqbby8go > div:nth-child(2),
.ldio-xhaaqbby8go > div:nth-child(4) {
  width: 108px;
  height: 108px;
  top: 46px;
  left: 46px;
  animation: ldio-xhaaqbby8go 1s linear infinite reverse;
}
.ldio-xhaaqbby8go > div:nth-child(2) {
  border-color: transparent #1CBEF5 transparent #1CBEF5;
  border-color: transparent #1CBEF5 transparent #1CBEF5;
}
.ldio-xhaaqbby8go > div:nth-child(3) {
  border-color: transparent;
}
.ldio-xhaaqbby8go > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-xhaaqbby8go > div:nth-child(3) div:before,
.ldio-xhaaqbby8go > div:nth-child(3) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -16px;
  left: 48px;
  background: #1CBEF5;
  background: #4530B2;
  border-radius: 50%;
  box-shadow: 0 128px 0 0 #4530B2;
  box-shadow: 0 128px 0 0 #4530B2;
}
.ldio-xhaaqbby8go > div:nth-child(3) div:after {
  left: -16px;
  top: 48px;
  box-shadow: 128px 0 0 0 #4530B2;
  box-shadow: 128px 0 0 0 #4530B2;
}

.ldio-xhaaqbby8go > div:nth-child(4) {
  border-color: transparent;
}
.ldio-xhaaqbby8go > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-xhaaqbby8go > div:nth-child(4) div:before,
.ldio-xhaaqbby8go > div:nth-child(4) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -16px;
  left: 30px;
  background: #1CBEF5;
  background: #1CBEF5;
  border-radius: 50%;
  box-shadow: 0 92px 0 0 #1CBEF5;
  box-shadow: 0 92px 0 0 #1CBEF5;
}
.ldio-xhaaqbby8go > div:nth-child(4) div:after {
  left: -16px;
  top: 30px;
  box-shadow: 92px 0 0 0 #1CBEF5;
  box-shadow: 92px 0 0 0 #1CBEF5;
}
.loadingio-spinner-double-ring-nb8my2hxaul {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-xhaaqbby8go {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-xhaaqbby8go div {
  box-sizing: content-box;
}

@keyframes ldio-xhaaqbby8go {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
