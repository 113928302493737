.pass_ipbox {
  /* background-color: #191e31; */
  background: #dadada;
  color: #000;
  /* border: 1px solid #383d54; */
  border: none;
  padding: 0.5rem 1rem;
  /* color: #8698b1; */
  font-size: 16px;
  width: 100%;
}
.pass_list {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1.5rem;
  margin-bottom: 1rem;
}
.pass_label {
  width: 200px;
  /* color: #8698b1; */
  color: black;
  font-weight: 600;
  font-size: 20px;
}
.btn_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.submit_btn {
  /* background-color: #023ea8; */
  cursor: pointer;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 2px;
}
@media (max-width: 500px) {
  .pass_list {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem;
  }
  .pass_ipbox {
    width: 85%;
  }
  .pass_label {
    font-size: 1rem;
  }
}
