.modal--overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal__container {
  background: #fcfcfc;
  color: black;
  width: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 1rem;
  position: fixed;
  top: 1rem;
  left: 50%;
}
.modal__content {
  color: gray;
  font-size: 0.9rem;
}
.button__no {
  padding: 0.5rem 2rem;
  border: none;
  background: #4530B2;
  color: white;
  border-radius: 4px;
}
.button__yes {
  background: linear-gradient(to right, #4530B2, #1CBEF5);
  color: white;
  border: none;
  padding: 0.5rem 2rem;
  border-radius: 4px;
}
