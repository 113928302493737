.logo--shrink {
  font-size: 10px;
  text-align: center;
  padding-top: 0.5rem;
}
.left,
.left--shrink {
  /* background:#6D88C3; */
  /* background-color: white;
  box-shadow: 4px 0px 5px 0px rgba(0, 0, 0, 0.2); */
  background-color: #F2F4FF;
  color: black;
}
.top {
  width: 100%;
}
.user-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.logo__container {
  width: 5rem;
  height: 5rem;
  border-radius: 3rem;
  overflow: hidden;
  background: white;
}
.avatar-container--shrink {
  width: 20px;
  height: 20px;
  border-radius: 3rem;
  overflow: hidden;
}
.logo__rounded {
  width: 100%;
  object-fit: contain;
}
.user-name {
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.user-name--shrink {
  font-size: 10px;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.user-actions {
  width: 50%;
  display: flex;
  justify-content: space-between;
}
.user-actions--shrink {
  display: flex;
  flex-direction: column;
}
.poweroff {
  background: #013da8;
  border-radius: 50%;
  padding: 0.5rem;
}
.poweroff {
  background: #e6302c;
}
.bottom {
  width: 100%;
  height: 525px;
  margin-top: 1rem;
  overflow: auto;
}
.register {
  margin-top: 0.5rem;
}
.register--shrink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}
