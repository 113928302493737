.sendReferral-container {
  position: relative;
}
.referral-links {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 1rem;
}
.send-link {
  /* background: #191e31; */
  background: #fff;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  user-select: all;
  cursor: pointer;
}
.send-link:hover {
  /* background: #161a29; */
  background: #dadada;
}
.alert {
  position: absolute;
  top: -30px;
  left: 50%;
  color: white;
  /* background: forestgreen; */
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transform: translate(-50%, -50%);
}
