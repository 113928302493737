.item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 0;
  user-select: none;
  cursor: pointer;
}
.item-container--shrink {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0;
  user-select: none;
  cursor: pointer;
}
.extra__spacing{
  padding-top: 1.4rem;
}
.item {
  display: flex;
  align-items: center;
}
.link {
  text-decoration: none;
  color: black;
}
.icon {
  margin-right: 0.5rem;
  color: black;
}
.subLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  padding: 0.5rem;
}
