
.main-container {
  position: relative;
  display: flex;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  color: white;
  overflow: hidden;
}
.pop-up {
  position: absolute;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgb(60, 60, 60,0.7);
  cursor: pointer;
}
.pop-up-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 70%;
  background-color: white;
  box-shadow: 1px 7px 19px -1px rgba(0, 0, 0, 0.57);
    -webkit-box-shadow: 1px 7px 19px -1px rgba(0, 0, 0, 0.57);
    -moz-box-shadow: 1px 7px 19px -1px rgba(0, 0, 0, 0.57);
}
.pop-up-image {
  /* width: 50%;
  height: 70%; */
  width: 200px;
  margin-bottom: 20px;
}
.pop-up-text {
  color: black;
  width: 80%;
  text-align: center;
}
.left {
  height: 100vh;
  padding: 1rem;
  background: #0c1420;
  transition: all 0.4s ease-in;
  display: none;
}
.left--shrink {
  height: 100vh;
  padding: 1rem;
  transition: all 0.4s ease-in;
  background: #0c1420;
  display: none;
}
.right {
  flex: 1;
  overflow: auto;
  padding: 1rem;
}
.right--shrink {
  flex: 1;
  overflow: auto;
  padding: 1rem;
}


@media (min-width: 600px) {
  .pop-up-image {
    width: 400px;
  }
}
@media (min-width: 1180px) {
  .left {
    display: block;
    flex: 0.2;
  }
  .left--shrink {
    display: block;
    flex: 0.05;
  }
  .right {
    flex: 0.8;
  }
  .right--shrink {
    flex: 0.95;
  }
  .pop-up-image {
    width:550px;
  }
}
