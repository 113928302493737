.searchDiv select {
  width: 80px;
  height: 30px;
  background-color: #191e31;
  margin: 0 0.5rem;
}
.searchDiv input {
  background-color: #191e31;
  height: 30px;
  border: none;
  border: 1px solid darkgrey;
  color: white;
  padding: 0.2rem 1rem;
  font-size: 16px;
}
.searchDiv {
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
}
.directReferrals_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
}
.endName {
  font-size: 20px;
}
.previous-btn,
.next-btn {
  border: none;
  background: transparent;
  font-size: 20px;
  margin: 0 0.5rem;
  color: white;
}
.btn {
  border: none;
  border-radius: 50%;
  background: #013da8;
  color: white;
  padding: 0.5rem 0.75rem;
  border: 4px solid white;
}
