.form_list {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1.5rem;
}
.form_title {
  width: 200px;
  /* color: #8698b1; */
  color: #101010;
  font-weight: 600;
  font-size: 20px;
}
.form_box {
  /* background-color: #191e31; */
  background: #f1f1f1;
  color: black;
  /* border: 1px solid #383d54; */
  padding: 0.5rem 1rem;
  /* color: #8698b1; */
  color: #000;
  font-size: 16px;
  width: 100%;
  min-width: 125px;
  border: none;
}

@media (max-width: 600px) {
  .form_title {
    width: 100px;
    /* color: #8698b1; */
    font-weight: 600;
    font-size: 14px;
  }
  .form_box {
    font-size: 12px;
  }
}
.form_box:disabled {
  /* background: #10131f; */
  background: #dadada;
}
.form__disabled {
  cursor: not-allowed;
}
.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edit__btn {
  /* background: #013da8; */
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  margin-right: 1.5rem;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
