.balance-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.balance {
  /* background: #191e31; */
  background: #dadada;
  padding: 1rem 1.5rem;
  width: 325px;
  margin: 0;
}
.balance-title {
  font-size: 1.7rem;
  margin-bottom: 0.5rem;
}
.balance-amount {
  /* color: green; */
  font-size: 1.2rem;
}
.withdraw-top {
  margin-bottom: 1rem;
  user-select: none;
}
.withdraw-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}
.withdraw-formWrapper {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.withdraw-select {
  width: 300px;
  height: 38px;
  /* color: white; */
  /* background: #191e31; */
  color: black;
  background: #ffff;
  outline: none;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid lightgray;
}
.withdraw-formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}
.withdraw-formLabel {
  font-size: 12px;
  /* color: #b9caed; */
  color: #000;
  margin-bottom: 0.5rem;
}
.withdraw-formInput {
  outline: none;
  width: 300px;
  /* background: #191e31; */
  color: black;
  background: #ffff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid lightgray;
  /* color: white; */
}
.withdraw-formSelect {
  outline: none;
  color: black;
  background: #ffff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid lightgray;
}
.withdraw-button {
  margin-top: 2rem;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  border: none;
  outline: none;
  /* background: #013da8; */
  color: white;
  cursor: pointer;
  transition: all 0.4s;
}
.withdraw-button:disabled {
  background-image: linear-gradient(to right, #4530b2, #1CBEF5);
  cursor: not-allowed;
}

.balance-amount {
  display: flex;
  justify-content: space-between;
}



@media (max-width: 1000px) {
  .balance-container {
    flex-direction: column;
  }
  .balance {
    margin: 0.5rem;
  }
  .withdraw-select {
    width: 334px;
  }
  .withdraw-formWrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .withdraw-select {
    margin: 0;
  }
}
@media (max-width: 400px) {
  .withdraw-select {
    width: 224px;
  }
  .withdraw-formInput {
    width: 190px;
  }
  .balance {
    width: 190px;
    padding: 0.5rem 1rem;
  }
  .balance-title {
    font-size: 0.9rem;
  }
  .balance-amount {
    font-size: 0.6rem;
  }
}

.issue-tw {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.issue-text-tw {
  color: black;
}

.check-container {
  display: flex;
  align-items: center;
}

.check-button {
  width: 40%;
  color: white;
  font-weight: 500;
  height: 40px;
  background-image: linear-gradient(to right, #4530b2, #1CBEF5);
  border: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

.check-name { 
  margin-left: 20px;
}