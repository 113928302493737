.logo-center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  border-radius: 300px;
  margin: 0 auto;
  padding: 0.6rem 0;
}
.logo-middle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-container {
  width: 10rem;
  height: 5rem;
}
.logo {
  width: 100%;
  object-fit: contain;
}
.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 1.5rem;
  background: #1CBEF5;
  
}
.referral-container {
  width: 500px;
  height: 100%;
}
.referral-page-title {
  margin: 1.5rem 2rem;
  font-size: 20px;
}
.form-referral {
  padding: 1.5rem;
}
.form_group-referral {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
}
.form_group-referral1 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.group-referral {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 1 1 100px;
  margin: 0.5rem;
}
.form_label-referral {
  margin-bottom: 0.5rem;
  font-size: 14px;
}
.form_label-referral1 {
  margin-bottom: 0.5rem;
  font-size: 14px;
}
.form_input-referral {
  /* color: white; */
  color: #000;
  padding: 0.7rem 1rem;
  /* background: #191e31; */
  background: #d3d3d3;
  border: none;
  font-size: 1rem;
}
.form_input-referral1 {
  color: black;
  padding: 0.7rem 1rem;
  /* background: white; */
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border: none;
  font-size: 1rem;
  cursor: not-allowed;
}
.form_select-referral {
  width: 100%;
  /* color: white; */
  /* background: #191e31; */
  background: #dadada;
  color: #000;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  height: 44px;
}
.btn-spacing {
  margin-top: 1.5rem;
}
.check-btn{
  padding: 00.5rem;
  margin-left: 1rem;
  margin-top: 1rem;
  color: green;
  font-size: large;
  background-color: transparent;
  border-style: none;
  
}
.success__message {
  padding: 1rem 2rem;
  /* background: #191e31; */
  background: #fafafa;
  color: #000;
  width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
.success__messageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  margin-top: 1rem;
}
.second__line {
  margin-top: 0.5rem;
}
.back__link {
  /* color: white; */
  color: #000;
  margin-left: 0.3rem;
  text-decoration: underline;
}

.confirm__container {
  min-width: 200px;
  width: 250px;
  margin: 1rem auto;
  /* background: #191e31; */
  background: #fafafa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  color: #000;
  padding: 1rem 2rem;
}
.confirm__formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.4rem;
}
.confirm__formLabel {
  /* color: white; */
  color: black;
  font-size: 12px;
  margin-bottom: 0.3rem;
}
.confirm__formContent {
  background: #d3d3d3;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: not-allowed;
}

.button__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  width: 100%;
}
