.dashboard-content {
  /* background-color: #192032; */
  margin-top: 1.5rem;
}
.info-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  gap: 0.5rem;
}
.info {
  padding: 1.5rem;
  /* background: #222840; */
  margin-bottom: 0.5rem;
  flex: 1 1 300px;
  background: linear-gradient(to right, #4530B2, #1CBEF5);
  background-color: #009DCA;
}
.info__today {
    padding: 1.5rem;
    margin-bottom: 0.5rem;
    flex: 1 1 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #4530B2;
}
.info h3 {
  font-size: 1.5rem;
  margin-bottom: 0.3rem;
}
.info h5 {
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 0.3rem;
}
.info p {
  /* color: green; */
  font-size: 1.5rem;
}

.scroller-container {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
.scroller {
  color: red;
  font-weight: 600;
  transform: translateX(100%);
  animation: scrolling-text 30s linear infinite;
}

@keyframes scrolling-text {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}



@media (max-width: 850px) {
  .info-container {
    justify-content: space-around;
  }

}
@media (max-width: 450px) {
  .info h3 {
    font-size: 1.3rem;
  }
  .info h5 {
    font-size: 0.9rem;
  }
  .scroller {
    animation: scrolling-text 20s linear infinite;
  }
  @keyframes scrolling-text {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-900px);
    }
  }
}
