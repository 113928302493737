.spacing {
  padding: 1rem;
  padding-top: 0.2rem;
}
.close {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}
.extra_spacing{
padding-top: 1.4rem;
}
.drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100vh;
  /* background: #0c1420 */
  background: #F2F4FF;
  color: black;
  overflow: auto;
  margin-bottom: 0.5rem;
}
.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: #F2F4FF;
  filter: blur(8px);
  width: 100vw;
  height: 100vh;
}
.drawer-bottom {
  margin-top: 1rem;
  width: 100%;
  color: white;
}
.register__link {
  padding: 0.5rem 1rem;
  margin-left: 1rem;
}
